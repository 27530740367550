.flatpickr-calendar {
    width: fit-content !important;
    border: none;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.flatpickr-calendar.inline {
    margin-top: 0 !important;
}

.flatpickr-calendar .flatpickr-months {
    display: none !important;
}

.input-not-clickable {
    pointer-events: none;
}

.input-hidden {
    display: none !important;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
    display: none;
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
    background: var(--color-purple-400) !important;
    border-color: var(--color-purple-300) !important;
    border-width: 2px !important;
    box-shadow: none;
    color: var(--color-white) !important;
    font-weight: bold !important;
    font-size: 16px !important;
}

.invalid .flatpickr-day.endRange,
.invalid .flatpickr-day.endRange.inRange,
.invalid .flatpickr-day.endRange.nextMonthDay,
.invalid .flatpickr-day.endRange.prevMonthDay,
.invalid .flatpickr-day.endRange:focus,
.invalid .flatpickr-day.endRange:hover,
.invalid .flatpickr-day.selected,
.invalid .flatpickr-day.selected.inRange,
.invalid .flatpickr-day.selected.nextMonthDay,
.invalid .flatpickr-day.selected.prevMonthDay,
.invalid .flatpickr-day.selected:hover,
.invalid .flatpickr-day.startRange,
.invalid .flatpickr-day.startRange.inRange,
.invalid .flatpickr-day.startRange.nextMonthDay,
.invalid .flatpickr-day.startRange.prevMonthDay,
.invalid .flatpickr-day.startRange:focus,
.invalid .flatpickr-day.startRange:hover {
    background: var(--color-red-500) !important;
    border-color: var(--color-red-300) !important;
    border-width: 2px !important;
    box-shadow: none;
    color: var(--color-white) !important;
    font-weight: bold !important;
    font-size: 16px !important;
}