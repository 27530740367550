@import 'tailwindcss/base.css';
@import 'tailwindcss/components.css';
@import 'tailwindcss/utilities.css';
@import 'flatpickr/dist/flatpickr.css';
@import './config/tailwind.utilities.css';

body {
    margin: 0;
    padding: 0;
}

:focus-visible { box-shadow: none !important; }